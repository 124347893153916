
.side-bar-icon{
    font-size: 1.2em;
    margin-right: 10px;
    color: #5b6e88;
}
.active>.side-bar-icon{
    color: #0162e8;
}

.text-align-right{
    text-align: right;
}

.big-modal{
    max-width: 95% !important;
}

.verical-middle{
    vertical-align: middle !important;
}

.display-inline{
    display: inline !important;
}

.k-input{
    font-weight: 300 !important;
}

.font-bold{
    font-size: medium;
    font-weight: bold;
}

.search-button {
    margin-top: 26px;
}
.uppercase{
    text-transform: uppercase;
}

.danger{
    background-color: #f5c6cb;
    border-color: #ed969e;
}
.success{
    background-color: #c8e9b8;
    border-color: #99d67b;
}